/* Built In Imports */
import { useRouter } from 'next/router';
import { useEffect } from 'react';

/* Internal Imports */
/* Components */
import { isVisInViewport } from '@components/Utility/Shared/SharedService';
import { mixPanel } from '@utils/Mixpanel';

export const useFormImpression = sectionId => {
  let isFormVisible = false;
  const router = useRouter();

  useEffect(() => {
    window.addEventListener('scroll', listenToScroll);
    return () => window.removeEventListener('scroll', listenToScroll);
  }, []);

  const listenToScroll = () => {
    const enrollSectionDiv = document.getElementById(sectionId);

    const inViewPort = enrollSectionDiv && isVisInViewport(enrollSectionDiv);
    if (!isFormVisible && inViewPort && enrollSectionDiv) {
      isFormVisible = true;
      const Mixpanel = mixPanel(router.asPath);
      if (Object.keys(Mixpanel)?.length > 0) {
        Mixpanel.track('ie_registration_form_impression', {});
      }
    }
  };
};
